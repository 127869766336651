import React from "react";

type Props = {
  fontSize?: string;
  remitColor?: 'black' | 'white'
}

const Logo: React.FC<Props> = ({fontSize, remitColor}) => {
  return (
    <a href="/">
      <span className="leading-1 text-center">
      <span className={`${fontSize ? `${fontSize}` : 'text-h4'} ${remitColor === 'white' ? `text-white` : 'text-black'} font-bold font-rale-way`}>remit</span>
      <span className={`${fontSize ? `${fontSize}` : 'text-h4'} font-bold font-rale-way text-gray-1`}>.</span>
      <span className={`${fontSize ? `${fontSize}` : 'text-h4'} font-bold text-pink-theme-1 font-rale-way`}>ai</span>
    </span>
    </a>
    
  )
};

export default Logo;