import { getCountryListFailure, getCountryListSuccess, getCountryListlLoading } from './reducers';
import { AppDispatch } from '../../../types/store';
import AxiosUtils from '../../../utils/axios-utils';
import { API } from '../../api-route';

export const fetchCountryList = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getCountryListlLoading());
    const res: any = await AxiosUtils.get(API.countryList);
    const newArray = res.data?.data?.values?.map((item: string) => {
      return {
        value: item,
        label: item?.toLocaleLowerCase()?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
      }
    });
    dispatch(getCountryListSuccess(newArray));
  } catch (error: any) {
    dispatch(getCountryListFailure(error));
  }
};