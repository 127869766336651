import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../types/store";
import { ONLY_NUMBER_MESSAGE, REQUIRED_MESSAGE, SAME_PASS_MESSAGE } from "./constant";
import dayjs, { Dayjs } from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween)

export const useAppDisPatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector; 

export const paths = {
  getStart: '/',
  home: '/chat',
  signUpAtlas: '/signup',
  privacyPolicy: '/privacy-notice',
  termsOfUse: '/terms-and-conditions',
}

export const checkPassword = (_: any, value: string) => {
  if (value?.length === 0) {
    return Promise.reject(new Error(REQUIRED_MESSAGE));
  }
  if (/\s/g.test(value)) {
    return Promise.reject(new Error('The password can not contain space'));
  }
  if (value?.length < 8 || value?.length > 30) {
    return Promise.reject(new Error('Password must be in 8-30 characters range'));
  }
  return Promise.resolve();
};

export const checkSame = (p1: string, p2: string) => {
  if (p2?.length === 0) {
    return Promise.reject(new Error(REQUIRED_MESSAGE));
  }
  if (p1 !== p2) {
    return Promise.reject(new Error(SAME_PASS_MESSAGE));
  }
  return Promise.resolve();
}

export const checkNumber = (_: any, value: string) => {
  if (value.length) {
    let isnum = /^\d+$/.test(value);
    if (!isnum) {
      return Promise.reject(new Error(ONLY_NUMBER_MESSAGE));
    }
  }
  return Promise.resolve()
}

export const generateId = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const checkDOB = (_: any, date: Dayjs) => {
  const firstDate  = dayjs().subtract(100, 'years');
  const lastDate = dayjs().subtract(18, 'years');
  if (date) {
    if (date.isBetween(firstDate, lastDate)){
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Date of birth must be from ${firstDate.format('DD/MM/YYYY')} to ${lastDate.format('DD/MM/YYYY')}`));
    }
  } else {
    return Promise.resolve();
  }
}

export const checkIDExpiry = (_: any, date: Dayjs) => {
  const now = dayjs();
  const lastDate = dayjs().add(100, 'years');
  if (date) {
    if (date.isBefore(now)) {
      return Promise.reject(new Error('ID Number expired, please change ID Expiry and ID Number') );
    } else if (date.isAfter(lastDate)) {
      return Promise.reject(new Error(`ID Expiry Date must be from ${now.format('DD/MM/YYYY')} to ${lastDate.format('DD/MM/YYYY')}`));
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
}

export const checkPhoneNumber = (_: any, value: string) => {
  if (value) {
    if (value.length >= 7) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('Too short, phone number must be longer'));
    }
  } else {
    return Promise.resolve();
  }
}

export const checkEmail = (_:any, value: string) => {
  if(value) {
    const emailValidate = String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    if (emailValidate) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Please enter valid email. Eg: 'abc@remitai.com'"));
    }
  }
  return Promise.resolve();
}

export const parseAtlasData = (rawData: any) => {

  const reg_add = rawData["regadd"];
  const mobileno = rawData["mobileno"]; 

  const block = reg_add?.block?.value ?? '';
  const street = reg_add?.street?.value ?? '';
  const floor = reg_add?.floor?.value ?? '';
  const unit = reg_add?.unit?.value ?? '';
  const building = reg_add?.building?.value ?? '';
  const country = reg_add?.country?.desc ?? '';
  const postal = reg_add?.postal?.value ?? '';

  const addressObject = !block && !street && !floor && !unit && !building && !country && !postal ? {} : {
    address: `${block} ${street} #${floor}-${unit} ${building} ${country} ${postal}`.replace(/\s+/g,' ').trim(),
  }


  const atlas_data = {
    "phone_number": mobileno?.areacode?.value + mobileno?.nbr?.value,
    "email": rawData?.email?.value ? rawData?.email?.value : '',
    // "address":`${reg_add?.block?.value} ${reg_add?.street?.value} #${reg_add?.floor?.value}-${reg_add?.unit?.value} ${reg_add?.building?.value} ${reg_add?.country?.desc} ${reg_add?.postal?.value}`,
    "id_number": rawData?.uinfin?.value,
    "id_type": "NATIONAL_ID",
    "id_expiry_date": null,
    "full_name": rawData?.name?.value,
    "gender": rawData?.sex?.desc,
    "date_of_birth": rawData?.dob?.value,
    "nationality": rawData?.birthcountry?.desc,
    "country_of_birth": rawData?.birthcountry?.desc,
    "country": rawData?.birthcountry?.desc,
    // "occupation": rawData?.occupation?.value,
    "residential_status": rawData?.residentialstatus?.desc,
    ...addressObject,
  }

  return atlas_data;
}
