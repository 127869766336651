import React, { useEffect, useState } from "react";
import Scroll from "react-scroll";
import BotMessage from "../../components/bot-message";
import MyMessage from "../../components/my-message";
import SideBar from "./side-bar";
import Logo from "../../components/layout/logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import ChatBox from "./chat-box";
import TopBar from "./top-bar";
import { Drawer } from "antd";
import { generateId, paths, useAppDisPatch } from "../../utils/common";
import { useSelector } from "react-redux";
import { RootState } from "../../types/store";
import { addMessages, resetMessagesData, setMessages } from "../../store/chat/messages/reducers";
import { IMessageItem } from "../../types/chat";
import { deleteConversation, updateConversations } from "../../store/chat/conversations/reducers";
import { useNavigate, useParams } from "react-router-dom";

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const ChatPage = () => {
  const dispatch = useAppDisPatch();
  const messagesStore = useSelector((state: RootState) => state.messages);
  const converSationStore = useSelector((state: RootState) => state.conversations);
  const [isBotLoading, setIsBotLoading] = useState<boolean>(false);
  const [chatText, setChatText] = useState<string>("");
  const [isOpenSideBar, setIsOpenSideBar] = useState<boolean>(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const messageData = messagesStore.data;
  const navigate = useNavigate();
  const params = useParams();
  const [activeConversation, setActiveConversation] = useState<string | null>(params.id ? params.id : generateId(50));

  const scrollToLastMessage = () => {
    scroller.scrollTo(`lastElement`, {
      duration: 0,
      delay: 0,
      containerId: 'mainChatID',
      offset: 0,
    });
  }

  const onSendMessage = (message: string) => {
    dispatch(addMessages([
      {
        text: "",
        isBotMessage: true,
        responseFor: message?.trim(),
        isGenerated: false,
        isError: false,
        sessionId: activeConversation,
      },
    ]))
    setChatText("");
    !messagesStore.data.length && navigate(`/chat/c/${activeConversation}`)
    scrollToLastMessage();
  }
  
  const reset = () => {
    setActiveConversation(generateId(50));
    dispatch(resetMessagesData());
    setIsBotLoading(false);
    setChatText('');
    navigate(paths.home);
  }

  const onChangeActiveConver = (id: string) => {
    setActiveConversation(id);
    setChatText('');
    setIsBotLoading(false);
    navigate(`/chat/c/${id}`)
  }

  const onDeleteConver = (id: string) => {
    reset();
    dispatch(deleteConversation({id}));
  }

  useEffect(() => {
    if (messageData?.length) {
      dispatch(updateConversations({
        messages: messageData,
        id: activeConversation,
        topic: messageData[messageData.length - 1]?.responseFor ?? '',
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageData]);


  useEffect(() => {
    if (params.id) {
      setActiveConversation(params.id);
      const messages = converSationStore.data.find((item) => item.id === params.id)?.messages;
      if (messages) {
        dispatch(setMessages(messages));
        setActiveConversation(params.id);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (messagesStore.data.length) {
      setTimeout(() => {
        scrollToLastMessage();
      }, 1);
    }
  }, [messagesStore.data])

  return (
    <div className="h-auto w-[100vw] fixed">
        <section className="flex">
      {/* SIDEBAR */}
      {/* MOBILE */}
      <Drawer
        placement={'left'}
        closable={false}
        onClose={() => setIsOpenDrawer(false)}
        open={isOpenDrawer}
        key={'left_lef'}
        width={300}
        styles={{
          body: {
            padding: 0
          }
        }}
      >
        <SideBar onAddNewChat={reset} activeConver={activeConversation} onChangeActiveConver={onChangeActiveConver} onDeleteConver={onDeleteConver}/>
      </Drawer>
      {/* PC */}
      <div
        className={`min-h-screen ${
          isOpenSideBar ? "w-[300px]" : "w-0"
        } duration-500 max-lg:hidden xl:block`}
      >
        {/* SIDE BAR */}
        <div className="relative">
          <div
            className={`flex h-[72px] w-8 items-center justify-center absolute top-1/2 transform -translate-y-1/2 right-[-32px] z-10`}
            onClick={() => {
              setIsOpenSideBar(!isOpenSideBar)
            }}
          >
            <div className="flex h-6 w-6 flex-col items-center text-gray-1 hover:text-black cursor-pointer"> 
              <FontAwesomeIcon icon={faAngleLeft} className={`${isOpenSideBar ? 'rotate-[0]' : 'rotate-[180deg]'} text-lg duration-150`} />
            </div>
          </div>
          <SideBar onAddNewChat={reset} activeConver={activeConversation} onChangeActiveConver={onChangeActiveConver} onDeleteConver={onDeleteConver} />
        </div>
      </div>

      {/* MAIN CHAT */}
      <div className="flex-1 flex">
        <div className="flex-1 relative overflow-y-auto" id='mainChatID' >
          {/* TOPBAR */}
          <div className={`fixed duration-500 top-0 left-0 max-lg:hidden xl:block ${isOpenSideBar ? 'left-[300px] w-[calc(100%_-_300px)]' : 'left-0 w-full' } bg-white`}>
            <TopBar isOpenSideBar={isOpenSideBar} reset={reset} />
          </div>
          <div className={`fixed top:0 block sm:max-md:block lg:hidden left-0 w-full bg-white`}>
            <TopBar isOpenSideBar={isOpenSideBar} reset={reset} toggleDrawer={() => setIsOpenDrawer(!isOpenDrawer)} />
          </div>

          {/* MESSAGES */}
          <div className="h-screen mt-20">
            {messageData?.length ? (
              <div className="flex justify-center">
                <div className="w-[900px] max-xl:w-[700px] max-lg:w-[700px] max-md:w-[100%] max-md:px-4 max-sm:px-2 flex flex-col">
                  {messageData.map((item: IMessageItem, index: number) => {
                    return (
                      <div key={index}>
                        <MyMessage message={item?.responseFor ?? ''} />
                        <BotMessage
                          messageItem={item}
                          index={index}
                          onChangeBotLoading={setIsBotLoading}
                          isBotLoading={isBotLoading}
                        />
                      </div>
                    )
                  })}
                  <div className="h-[240px]"></div>
                  <Element name='lastElement' />
                </div>
              </div>
            ) : (
              <div className="h-full w-full relative box-border">
                <div className="text-center">
                  <Logo />
                  <div>
                    <span className="text-gray-1 text-lg">How can I help you today?</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* CHAT BOX */}
          <div className={`fixed max-lg:hidden xl:block ${isOpenSideBar ? 'left-[300px] w-[calc(100%_-_300px)]' : 'left-0 w-full' } duration-500 bottom-0 border-box pt-[16px] pb-10 bg-white`}>
            <ChatBox messages={messageData} chatText={chatText} onChangeChatText={setChatText} isLoading={isBotLoading} onSendMessage={onSendMessage} />
          </div>
          <div className={`fixed block sm:max-md:block lg:hidden left-0 w-full bottom-0 border-box pt-[16px] pb-10 bg-white `}>
            <ChatBox messages={messageData} chatText={chatText} onChangeChatText={setChatText} isLoading={isBotLoading} onSendMessage={onSendMessage} />
          </div>
        </div>  
      </div>
    </section>
    </div>
  
  );
};

export default ChatPage;
