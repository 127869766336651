import { ThemeConfig } from "antd";

const REQUIRED_MESSAGE = 'This field is required';
const SAME_PASS_MESSAGE = 'Password and confirm password must be the same';
const ONLY_NUMBER_MESSAGE = 'Only number is allowed';

const QUICK_QUESTIONS = [
  {
    title: 'Which countries can I transfer money to? ',
    subTitle: 'ask about remitai service',
  },
  {
    title: 'When will my receiver get my funds?',
    subTitle: 'ask about remitai service',
  },
  {
    title: 'Why can’t I get a refund immediately?',
    subTitle: 'ask about remitai service',
  },
  {
    title: 'Is my data safe with you?',
    subTitle: 'ask about remitai service',
  }
]

const ANTD_THEME_CONFIG: ThemeConfig = {
  components: {
    Button: {
      colorPrimaryBorderHover: 'rgba(255, 108, 125, 1)',
      colorPrimaryHover: 'rgba(255, 108, 125, 1)',
      colorPrimary: 'rgba(255, 108, 125, 1)',
      colorPrimaryActive: 'rgba(255, 108, 125, 1)',
      colorPrimaryTextHover: 'rgba(255, 108, 125, 1)',
    },
    Input: {
      colorBorder: 'white',
      activeBorderColor: 'white',
      hoverBorderColor: 'white',
      colorPrimaryBorder: 'white',
      colorError: 'rgba(255, 108, 125, 1)',
    },
    Select: {
      colorBorder: 'white',
      colorPrimaryBorder: 'white',
      colorError: 'rgba(255, 108, 125, 1)',
      colorBorderBg: 'white',
      colorInfoBorderHover: 'white',
      colorPrimaryBorderHover: 'white',
      colorPrimaryActive: 'white',
      colorPrimaryHover: 'white',
      colorPrimaryBg: 'white',
      colorPrimaryTextActive: 'white',
      colorPrimary: 'white',
      colorPrimaryBgHover: 'white'
    },
    DatePicker: {
      colorBorder: 'white',
      activeBorderColor: 'white',
      hoverBorderColor: 'white',
      colorPrimaryBorder: 'white',
      colorError: 'rgba(255, 108, 125, 1)',
    },
    Form: {
      colorError: 'rgba(255, 108, 125, 1)',
    },
  },
}

const ANONYMOUS_USER_ID = 'anonymous';


export {
  REQUIRED_MESSAGE,
  SAME_PASS_MESSAGE,
  ONLY_NUMBER_MESSAGE,
  QUICK_QUESTIONS,
  ANTD_THEME_CONFIG,
  ANONYMOUS_USER_ID,
}