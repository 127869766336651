import React from 'react';
import {  marked } from 'marked';
import SignInPage from './pages/sign-in';
import SignUpPage from './pages/sign-up';
import ChatPage from './pages/chat';
import { IRouteObject } from './types/route';
import { Route, Routes } from 'react-router-dom';
import { paths } from './utils/common';
import ProtectedRoute from './routes/protected-route';
import { PrivacyPolicyPage } from './pages/privacy-policy';
import { TermsOfUsePage } from './pages/terms-of-use';

function App() {

  const mainRoutes: IRouteObject[] = [
    {
      path: paths.getStart,
      component: SignInPage,
      isPublish: true,
    },
    {
      path: paths.signUpAtlas,
      component: SignUpPage,
      isPublish: true,
    },
    {
      path: paths.privacyPolicy,
      component: PrivacyPolicyPage,
      isPublish: true,
    },
    {
      path: paths.termsOfUse,
      component: TermsOfUsePage,
      isPublish: true,
    },
    {
      path: paths.home,
      component: ChatPage,
      isPublish: false,
    },
    {
      path: '/chat/c/:id',
      component: ChatPage,
      isPublish: false,
    }
  ]

  const renderer = {
    code(text: any, level: any) {
      return `<div style="margin-bottom: 8px; background: #282a36; color: #ffb86c; font-family: 'Roboto', sans-serif; font-weight: 400; border-radius: 6px"><div style="background: gray; height: 24px; width: 100%; border-radius: 6px 6px 0 0"></div><div style="padding: 12px;">${text}</div></div>`;
    },
    link(href: any, title: any, text: string) {
      return `<a target="_blank" href=${href} class='text-pink-theme-1 underline'>${text}</a>`
    }
  };
  marked.use({ renderer });

  return (
    <div className="App h-auto w-full font-roboto">
      <Routes>
      {
        mainRoutes.map((route, index) => {
          const Component = route.component;
          if (route.isPublish) {
            return <Route key={index} path={route.path} element={<Component />} />
          } else {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    <Component />
                  </ProtectedRoute>
                }
              />
            );
          }
        })
      }
      </Routes>
      
    </div>
  );
}

export default App;
