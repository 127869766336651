import React from "react";

import { QAndAItem } from "./q-and-a-item";

export const QAndA = () => {

  const answers = [
    {
      question: 'What is remit.ai?',
      answer: 'Remit.ai is an intelligent remittance platform designed to help you send money internationally with ease. Powered by AI, it allows users to interact via WhatsApp or web interface for fast and reliable money transfers. We are backed by Ample Transfers Pte Ltd, a licensed Major Payment Institution (MPI) regulated by the Monetary Authority of Singapore (MAS). You can verify our license and regulatory status <a target="_blank" class="text-pink-theme-1 underline" href="https://ampl.fyi/licence">here</a>.'
    },
    {
      question: 'How does Remit.ai work?',
      answer: `Remit.ai functions like a personal assistant for remittance. Simply chat with us via WhatsApp or our website to get started—no sign-up required. You can enquire, initiate, and track your transactions seamlessly through the platform. To start transacting, we'll first need to register you, and the KYC process requires you to establish your identity, which will involve providing your identity documents.`
    },
    {
      question: 'Which countries can I send money to using Remit.ai?',
      answer: 'Remit.ai enables money transfers from Singapore to virtually any country in the world. You can send in any currency the destination country can receive. Payment is made in SGD. Chat with us now to learn more about your specific destination!'
    },
    {
      question: 'What do I need to use Remit.ai?',
      answer: `All you need is WhatsApp. To start transacting, we’ll first need to register you, and the KYC process requires you to establish your identity, which will involve your identity documents.`
    },
    {
      question: 'Is Remit.ai safe and secure?',
      answer: `Yes, Remit.ai uses state-of-the-art encryption and security protocols to ensure that your transactions and personal information are safe. We adhere to international standards and comply with regulatory requirements such as the <a target="_blank" class="text-pink-theme-1 underline" href='https://www.mas.gov.sg/regulation/notices/psn01-aml-cft-notice---specified-payment-services'>MAS PSN01</a>.`
    },
    {
      question: 'Why does Remit.ai need to ask for personal information?',
      answer: `We are required by law to collect certain information from our users for Anti-Money Laundering (AML) and Countering the Financing of Terrorism (CFT) compliance. This ensures the safety and security of your transactions and helps us prevent illegal activities like fraud and money laundering.`
    },
    {
      question: 'How can I pay for my transactions?',
      answer: `Payment is made via a unique QR code generated for each transaction. You will need to make the payment via PayNow through this QR code. Once payment is completed, your transaction is processed within minutes.`
    },
    {
      question: 'How much does it cost to send money through Remit.ai?',
      answer: `Our fees are competitive and vary depending on the destination and transfer amount. You’ll see a clear breakdown of fees before confirming any transaction, ensuring full transparency.`
    },
    {
      question: 'How long does a money transfer take?',
      answer: `Transfers typically take from a few minutes to a few hours depending on the destination country, payment method, and receiving bank. In most cases, transfers are completed within the same day. `
    },
    {
      question: 'What currencies are supported?',
      answer: `You can send to any currency the destination country can receive. Payment is made in SGD. Chat with us now to learn more!`
    },
    {
      question: 'Is there a transaction limit?',
      answer: `Yes, there are daily and monthly limits in place based on regulatory requirements and your account verification level. You’ll be informed of your available limits during the transaction process. `
    },
    {
      question: 'How do I contact customer support?',
      answer: `
      You can contact our customer support team via WhatsApp at <a target="_blank" href="https://wa.me/6591709363" class="text-pink-theme-1 underline">https://wa.me/6591709363</a>. We're here to help with any questions or issues you may have.
      `
    },
    {
      question: 'Can I set up recurring transfers?',
      answer: `
      At this time, <a target="_blank" href="https://remit.ai" class="text-pink-theme-1 underline">remit.ai</a> is designed to cater to individual consumers and provide services directly to end users. Unfortunately, we cannot onboard corporate entities onto <a target="_blank" href="https://remit.ai" class="text-pink-theme-1 underline">remit.ai</a> just yet. Stay tuned for future updates, as we’re always looking to improve our offerings!
      `
    },
    {
      question: 'Can I send money to corporate or business accounts in China, India, or Bangladesh?',
      answer: `No, our service does not support sending money to corporate or business accounts in China, India, or Bangladesh. We only allow transfers to individual recipient accounts in these countries.`
    },
    {
      question: `Can I set up recurring transfers?`,
      answer: `We’re unable to set up remittances that recur monthly, and we advise against such arrangements due to potential security-related risks. But don’t worry! Our simple, fuss-free service makes it easy to send money whenever you need. Just type something like "Send $200 to Mum," and you’re good to go!`
    },
    {
      question: `Does <a target="_blank" href="https://remit.ai" class="text-pink-theme-1 underline">remit.ai</a> have physical outlets?`,
      answer: `<a target="_blank" href="https://remit.ai" class="text-pink-theme-1 underline">remit.ai</a> operates entirely online and does not have physical outlets. However, you may have noticed that we are powered by Ample Transfers, a licensed Money Payment Institution (MPI). Ample Transfers does have physical outlets, which you can find <a target="_blank" href="https://ampletransfers.com/locations/" class="text-pink-theme-1 underline">here</a>.`
    },
    {
      question: 'What is MYRX?',
      answer: `The national currency of Malaysia is the Malaysian Ringgit (MYR). However, due to the unique requirements for making transfers to external accounts (accounts held by non-Malaysian nationals), we utilise different bank clearing channels to ensure your money reaches its destination securely and without issues.</br>
      These differences can sometimes result in varying exchange rates, and we want to make this distinction clear for our customers. That's why we label it as MYRX.
      `
    }
  ]

  return (
    <div className="flex justify-center text-[#242424] mt-[100px] mb-[100px]">
      <div className="w-[956px] flex gap-8 flex-col md:flex-row">
        <div className="md:text-[48px] text-[32px] font-[700] w-[250px]">Frequently Asked Questions</div>
        <div className="flex-1">
          {
            answers.map((item, index) => (
              <QAndAItem answer={item.answer} question={item.question} key={index} isShowLine={index < answers?.length - 1 }/>
            ))
          }
        </div>
      </div>
    </div>
  )
}