import React, { useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";

interface IProps {
  answer: string;
  question: string;
  isShowLine?: boolean;
}

export const QAndAItem: React.FC<IProps> = (props) => {
  const { answer, question, isShowLine = true } = props;
  const [open, setIsOpen] = useState<boolean>(false);

  return(
    <div>
      <div className="flex gap-2">
        <div className="w-[24px] h-[24px] mt-[2px]">
          <FontAwesomeIcon icon={faPlus} onClick={() => setIsOpen(prev => !prev)} className={`transition ease-in-out delay-100 cursor-pointer ${open ? 'rotate-45' : 'rotate-0'} text-[#636363]`} />
        </div>
        <div className="font-[700] text-[18px] font-cabin text-[#242424]" dangerouslySetInnerHTML={{ __html: question }}>
        </div>
      </div>
      {
        open && (
          <div className="ml-8 mt-4 font-cabin text-[#242424] leading-[24px]" dangerouslySetInnerHTML={{ __html: answer }} />
        )
      }
      {
        isShowLine && (
          <Divider />
        )
      }
    </div>
  )
}