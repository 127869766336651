import React from "react";
import { useNavigate } from "react-router-dom";
import AuthenLayout from "../../components/layout/authen";
import { RootState } from "../../types/store";
import { useAppDisPatch } from "../../utils/common";
import { useSelector } from "react-redux";
import { getToken } from "../../store/auth/get-token/actions";
import whatsappImage from '../../assets/image/whatsapp.png';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import Logo from "./../../components/layout/logo";
import shieldCheck from './../../assets/svg/shield-check.svg';
import dataAssure from './../../assets/image/data-assure.png';
import feature1 from './../../assets/image/feature-1.png';
import feature2 from './../../assets/image/feature-2.png';
import feature3 from './../../assets/image/feature-3.png';
import whatsappApp from './../../assets/image/whatsapp-app.png';
import ample from './../../assets/image/ample.png';
import openai from './../../assets/image/openai.png';
import { QAndA } from "./q-and-a";

const features = [
  {
    title: 'Send Money Without the Hassle - No App Required',
    description: 'Tired of downloading yet another app to send money? Our AI-driven platform lets you transfer money instantly through WhatsApp — Fast, secure, and easy.',
    imageSrc: feature1,
  },
  {
    title: 'Faster Transfers, Trusted by Thousands',
    description: 'With decades of experience in remittance services, we ensure your money gets to its destination fast and securely. While newer fintech startups are figuring things out, we’ve been perfecting remittance for years.',
    imageSrc: feature2,
  },
  {
    title: 'Instant Support at Your Fingertips',
    description: 'Our transparent fees and upfront pricing ensure a hassle-free experience. And if you need more, our expert team is ready to assist, all within the conveniences of WhatsApp.',
    imageSrc: feature3,
    color: '#262626'
  },
]

const SignInPage = () => {
  const dispatch = useAppDisPatch();
  const authStore = useSelector((state: RootState) => state.getToken);
  const navigate = useNavigate();

  const onGetToken = () => {
    dispatch(getToken(navigate));
  }

  return (
    <AuthenLayout>
      <div className="gradient-theme-secondary font-rale-way">
        <div className="mt-s-10 sm:mt-s-10 md:mt-s-20 mb-s-20 sm:mb-s-20 md:mb-s-30 px-[156px] flex lg:justify-start justify-center">
          <Logo fontSize="text-[28px] md:text-[40px]"/>
        </div>
        <div className="lg:flex hidden justify-center items-center gap-2 mt-4">
          <span className="text-[#545454] text-[18px] font-[400] font-cabin">Introducing</span> <Logo fontSize="text-[27px] leading-1" />
        </div>

        <div className="max-sm:p-4 p-5 font-rale-way flex justify-center items-center flex-col">
          <h2 className="slogan text-center">
            <span className="leading-tight lg:w-[800px] md:w-[600px] w-[320px] font-[700] text-[28px] lg:text-[60px] md:text-[40px] text-[#fa5863]">
              Forget the apps
            </span>&nbsp;
            <span className="leading-tight lg:w-[800px] md:w-[600px] w-[320px] font-[700] text-[28px] lg:text-[60px] md:text-[40px]">-</span>
          </h2>
          <h2 className="slogan text-center flex justify-center">
            <span className="leading-tight lg:w-[956px] md:w-[600px] w-[320px] font-[700] text-[28px] lg:text-[60px] md:text-[40px]">
              Transfer money safely and easily,
            </span>
          </h2>
          <div className="flex justify-center items-center gap-x-4 flex-wrap">
              <div className="text-[28px] lg:text-[60px] md:text-[40px] leading-tight font-[700]">
                all through
              </div>
              <div  className="w-[170px] lg:w-auto md:w-[280px]">
                <img alt='whatsapp' src={whatsappImage}/>
              </div>
            </div>
          <div className='mt-10'>
            <p className="text-center font-[400] leading-6 text-[16px] lg:text-[20px] font-cabin">
              Send money home securely with our easy-to-use chatbot.
            </p>
            <p className="text-center font-[400] leading-6 text-[16px] lg:text-[20px] font-cabin">
              No downloads, no hassle, just safe and transparent transfers.
            </p>
          </div>
          <div className="mx-auto my-0 w-[100%] lg:mt-12 mt-4">
            <div className="flex items-center justify-center gap-4">
              <div className="cursor-pointer flex text-white bg-[#f47680] h-[56px] rounded w-[230px] items-center justify-center gap-4" onClick={() => {
                window.location.href = 'https://wa.me/6588108486'
              }}>
                <span className="text-[16px] font-[600]">Start Sending Money</span>
                <FontAwesomeIcon icon={faArrowRight} className="text-[18px] font-[600]" />
              </div>
              <Button
                loading={authStore.isLoading}
                onClick={onGetToken}
                type='default'
                className="h-[56px] !border-pink-500 !text-black !bg-transparent text-[16px] !font-[600] font-rale-way"
              >Chat Now
              </Button>
            </div>
          </div>
        </div>

        <div className="flex justify-center px-4 mt-10" style={{ backgroundColor: '#feecf114' }}>
          <div className="w-[956px] py-10 flex gap-8 lg:justify-between lg:items-start items-center lg:flex-row md:flex-col flex-col">
            <div className="flex gap-8">
              <div>
                <p className="font-[400] md:text-[16px] text-[14px]">Licensed by</p>
                <div className="flex gap-1 items-center mt-2">
                  <img src={shieldCheck} alt='shield-check'/>
                  <p className="w-[160px] text-[#262626] lg:text-[18px] md:text-[16px] text-[14px] font-cabin">
                    Monetary Authority of Singapore
                  </p>
                </div>
              </div>

              <div>
                <p className="font-[400] md:text-[16px] text-[14px]">Endorsed with</p>
                <div className="mt-2">
                  <img src={dataAssure} alt='shield-check'/>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-[212px] lg:w-auto lg:mr-8 mr-0">
                <div className="font-[400] md:text-[16px] text-[14px]">Powered by</div>
                <div className="flex gap-8 mt-5">
                  <img src={openai} alt='openai'/>
                </div>
              </div>
              <div>
                <div className="font-[400] md:text-[16px] text-[14px]"></div>
                <div className="flex gap-8 mt-10">
                  <img src={ample} alt='ample'/>
                </div>
              </div>
            </div>
          </div>
        </div>
    
      </div>

      <div className="bg-[#f6f6f6] font-rale-way px-4">
        <div className="mt-[80px] flex justify-center">
          <div className="w-[956px]">
            {
              features.map((item, index) => {
                if (index % 2 === 0) {
                  return (
                    <div key={index} className={`flex md:flex-row flex-col mb-[80px] md:justify-between justify-center gap-8 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse justify-end'}`}>
                    <div className="max-w-[372px]">
                      <p className="md:text-[32px] text-[26px] font-[700] text-[#262626] leading-[48px]">{item.title}</p>
                      <p className="mt-2 md:text-[18px] text-[14px] text-[#262626] leading-[24px]">{item.description}</p>
                    </div>
                    <div>
                      <img src={item.imageSrc} alt='feature' className="rounded-3xl"/>
                    </div>
                  </div>
                  )
                } else {
                  return (
                    <div key={index} className={`flex mb-[80px] md:justify-between gap-8 md:flex-row-reverse justify-end flex-col`}>
                    <div className="max-w-[372px]">
                      <p className="md:text-[32px] text-[26px] font-[700] text-[#262626] leading-[48px]">{item.title}</p>
                      <p className="mt-2 md:text-[18px] text-[14px] text-[#262626] leading-[24px]">{item.description}</p>
                    </div>
                    <div>
                      <img src={item.imageSrc} alt='feature' className="rounded-3xl"/>
                    </div>
                  </div>
                  )
                }
              })
            }
          </div>
        </div>
        <div className="bg-[#fff] md:rounded-3xl rounded-md flex justify-center px-4 py-2">
          <div className="w-[956px] flex md:items-center items-start justify-start gap-6 md:justify-center md:flex-row flex-col">
            <div>
              <p className="font-[700] text-[#fa454d] whitespace-nowrap lg:text-[48px] md:text-[32px] text-[24px]">Start sending money</p>
              <p className="text-[18px] font-[400] mt-2">Just chat to get started.</p>
              <Button
                loading={authStore.isLoading}
                onClick={onGetToken}
                type='primary'
                className="h-[56px] bg-[#f4768D] text-[16px] !font-[600] px-8 mt-6 font-rale-way"
              >Chat Now
              </Button>
            </div>
            <div>
              <img src={whatsappApp} alt='whatsappApp'/>
            </div>
          </div>
        </div>

        <QAndA />
      </div>
    </AuthenLayout>
  )
};

export default SignInPage;
