import React, { useEffect, useState } from "react";
import Logo from "../logo";

interface Iprops {
  title: string;
}

export const TermHeader: React.FC<Iprops> = ({title}) => {
  const [isShowHeader, setIsShowHeader] = useState<boolean>(false);

  useEffect(() => {
    window.onscroll = function(event) {
      var body = window.document.body; //IE 'quirks'
      var document = window.document.documentElement; //IE with doctype
      document = (document.clientHeight) ? document : body;
      
      if (document.scrollTop > 280) {
        setIsShowHeader(true)
      } else {
        setIsShowHeader(false)
      }
  };
  }, []);


  return (
    <div className="gradient-theme-secondary h-[280px]">
        {/* <div className="absolute mt-4 left-[50%] translate-x-[-50%] md:translate-x-[0] md:left-0"> */}
          <div className="px-[0px] md:px-[156px] flex justify-center lg:justify-start mt-4"><Logo remitColor="black" fontSize="md:text-[40px] text-[32px]"/></div>
        {/* </div> */}

      <div className="flex justify-center px-4">
        <div className="max-w-[956px] flex-1 px-4">
          <div className="h-[280px]">
            <p className="md:text-[48px] text-[36px] font-[700] mt-[60px] font-rale-way">{title}</p>
            <p className="text-[16px] font-[400] mt-2 font-cabin">Last updated: 1st October 2024</p>
          </div>
        </div>
        <div className='fixed left-0 w-full py-4 bg-[#ffffff70]'
          style={{
            backdropFilter: 'blur(3px)' ,
            top: isShowHeader ? '0': '-100px',
            transition: 'all 0.2s ease-in-out',
            WebkitBackdropFilter: 'blur(3px)'
          }}
          >
            <div className="px-[156px] flex justify-center lg:justify-start"><Logo remitColor="black" fontSize="md:text-[40px] text-[32px]" /></div>
        </div>
      </div>
    </div>
  )
}