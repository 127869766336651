import { getSourceOfFundListFailure, getSourceOfFundListLoading, getSourceOfFundListSuccess } from './reducers';
import { AppDispatch } from '../../../types/store';
import AxiosUtils from '../../../utils/axios-utils';
import { API } from '../../api-route';

export const fetchSourceOfFundList = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getSourceOfFundListLoading());
    const res: any = await AxiosUtils.get(API.sourceOfFundList);
    const newArray = res.data?.data?.values?.map((item: string) => {
      return {
        value: item,
        label: item?.toLocaleLowerCase()?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())?.replace(/(^|\/|-)(\S)/g, s=>s.toUpperCase()),
      }
    });
    dispatch(getSourceOfFundListSuccess(newArray));
  } catch (error: any) {
    dispatch(getSourceOfFundListFailure(error));
  }
};